import '@fontsource/rubik';
import '@fontsource/work-sans';
import '@fontsource/jetbrains-mono';

import '@fontsource/catamaran/300.css';
import '@fontsource/catamaran/900.css';

export enum size {
	h1 = '4.8rem',
	h2 = '4.2rem',
	h3 = '3.6rem',
	h4 = '2.8rem',
	h5 = '2.2rem',
	h6 = '2rem',
	text = '1.6rem',
	small = '1.2rem',
}

export enum weight {
	thin = 300,
	normal = 400,
	semibold = 600,
	bold = 700,
	black = 900,
}

export enum family {
	logotype = '"Catamaran", helvetica, arial, sans-serif',
	heading = '"Work Sans", helvetica, arial, sans-serif',
	text = '"Rubik", helvetica, arial, sans-serif',
	mono = '"JetBrains Mono", monaco, courier new, monospace',
}

export function pair(f: family, s: size, w: weight = weight.normal): string {
	return `${w} ${s} ${f}`;
}
