import styled, { keyframes, css } from 'styled-components';

import * as styles from 'styles';

const RevealFillAnimation = keyframes`
	to {
		color: ${styles.palette.primary[500]};
	}
`;

const RevealAnimation = keyframes`
	to {
		stroke-dashoffset: 0;
	}
`;

const RevealDoneAnimation = keyframes`
	to {
		stroke: transparent;
	}
`;

const Wrapper = styled.div<{ animate: boolean }>`
	display: grid;
	grid-template-rows: repeat(2, auto);
	color: ${styles.palette.primary[500]};

	span {
		margin-top: 0.5rem;
		margin-right: 0.5rem;
		display: inline-block;
		font-family: ${styles.font.family.mono};
		font-weight: ${styles.font.weight.semibold};
		font-size: ${styles.font.size.small};
		font-style: italic;
		text-transform: uppercase;
		text-align: right;
		color: ${styles.palette.tertiary[700]};
	}

	${({ animate }) =>
		animate &&
		css`
			color: transparent;
			animation: ${RevealFillAnimation} 300ms ease-in-out forwards;
			animation-delay: 1400ms;

			svg path {
				stroke-width: 0.5;
				stroke: ${styles.palette.primary[500]};
				stroke-dasharray: 210;
				stroke-dashoffset: 210;
				animation: ${RevealAnimation} 1500ms linear forwards,
					${RevealDoneAnimation} 300ms linear forwards 1500ms;
			}
		`}
`;

interface LogotypeProps {
	subTitle?: string;
	animate?: boolean;
}

export default function Logotype({
	subTitle,
	animate = false,
}: LogotypeProps): JSX.Element {
	return (
		<Wrapper animate={animate}>
			<svg viewBox="0 0 232.44 45.35">
				<path
					fill="currentColor"
					d="M1.23,40.63l3.63-8.27a18.89,18.89,0,0,0,4.49,1.78,18.5,18.5,0,0,0,5,.82,6.64,6.64,0,0,0,3.86-1,2.73,2.73,0,0,0,1.45-2.2,2,2,0,0,0-1.05-1.54c-.71-.5-1.84-1.21-3.41-2.14L13.37,27C10.63,25.38,8.5,24.05,7,23a17.2,17.2,0,0,1-4.11-4.14,9.67,9.67,0,0,1-1.81-5.73A10.34,10.34,0,0,1,3,7,12.54,12.54,0,0,1,8.3,2.85a19.72,19.72,0,0,1,8-1.51,23.87,23.87,0,0,1,6.43.91,19.09,19.09,0,0,1,5.64,2.47l-3.25,7.42a47.51,47.51,0,0,0-4.92-1.44,17.82,17.82,0,0,0-3.78-.37,4.73,4.73,0,0,0-2.92.85,2.49,2.49,0,0,0-1.12,2.05,2,2,0,0,0,.94,1.6,26.15,26.15,0,0,0,3.1,1.84l2.18,1.21a72.87,72.87,0,0,1,6.33,4,19,19,0,0,1,4.32,4.35,9.48,9.48,0,0,1,1.78,5.64,10.22,10.22,0,0,1-2,6.22,13.24,13.24,0,0,1-5.7,4.28A22.1,22.1,0,0,1,14.69,44a30.62,30.62,0,0,1-7.15-1A23.29,23.29,0,0,1,1.23,40.63Zm34.35,2.54V2.12h11V41.18ZM62.66,2.12V26.93a7.94,7.94,0,0,0,2,5.83,8,8,0,0,0,10.75,0,8,8,0,0,0,2.05-5.83V2.12h11V27.48a16.72,16.72,0,0,1-2.29,9,14.58,14.58,0,0,1-6.46,5.64A22.73,22.73,0,0,1,70.08,44a22.65,22.65,0,0,1-9.65-1.93A14.58,14.58,0,0,1,54,36.44a16.72,16.72,0,0,1-2.3-9V2.12Zm60.4,41.05H93.6V2.12h11V34.54H122Zm3.92,0V2.12H154.2l1,8.64H138v7.48h12.61l1,8.63H138v7.67h18l1,8.63ZM161.57,2.12h17.26q7.36,0,12.13,3.51t4.77,10.5a13.2,13.2,0,0,1-1.48,6.37A12.45,12.45,0,0,1,190.18,27l9,16.18H186.49l-6.63-12.92h-7.31V43.17h-11Zm16.9,8.64h-5.92V21.62h6a5.78,5.78,0,0,0,4.16-1.54,5.17,5.17,0,0,0,1.63-3.95,5.08,5.08,0,0,0-1.54-4A6.4,6.4,0,0,0,178.47,10.76Zm37.26,7.6h-8.16a1.6,1.6,0,0,0-1.39.81l-4.08,7.07a1.6,1.6,0,0,0,0,1.61l4.08,7.07a1.59,1.59,0,0,0,1.39.8h8.16a1.61,1.61,0,0,0,1.4-.8l4.08-7.07a1.6,1.6,0,0,0,0-1.61l-4.08-7.07A1.62,1.62,0,0,0,215.73,18.36Zm11.89-11.3h-4.85a1.36,1.36,0,0,0-1.17.67l-2.42,4.2a1.36,1.36,0,0,0,0,1.35l2.42,4.2a1.36,1.36,0,0,0,1.17.67h4.85a1.35,1.35,0,0,0,1.16-.67l2.43-4.2a1.36,1.36,0,0,0,0-1.35l-2.43-4.2A1.35,1.35,0,0,0,227.62,7.06Z"
				/>
			</svg>
			{subTitle && <span>{subTitle}</span>}
		</Wrapper>
	);
}
