import styled, { keyframes } from 'styled-components';

import * as styles from 'styles';

import Logotype from 'views/components/Logotype';

const RevealAnimation = keyframes`
	0% {
		transform: translateY(-50%);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
`;

const Wrapper = styled.div`
	margin: 0 auto;
	padding: 10vh 0 0 0;
	width: 90%;
	max-width: 45rem;
	display: grid;
	gap: 5rem;
`;

const ContactInfo = styled.p`
	display: grid;
	grid-auto-flow: column;
	align-items: center;
	justify-content: center;
	gap: 5rem;
	opacity: 0;
	animation: ${RevealAnimation} 300ms ease-in-out forwards 1.4s;

	a {
		padding: 0.6rem 1rem;
		background-color: ${styles.palette.primary[50]};
		color: ${styles.palette.primary[500]};
		font: ${styles.font.pair(styles.font.family.mono, styles.font.size.text)};
		transition: color 200ms ease-in-out, background-color 200ms ease-in-out;
		will-change: color, background-color;
		border-radius: 0.2rem;

		@media (hover: hover) {
			&:hover {
				background-color: ${styles.palette.primary[100]};
				color: ${styles.palette.primary[700]};
			}
		}
	}
`;

export default function App(): JSX.Element {
	return (
		<Wrapper>
			<Logotype animate />
			<ContactInfo>
				<a
					href="mailto:info@siuler.se"
					rel="noopener noreferrer"
					target="_blank">
					info@siuler.se
				</a>
			</ContactInfo>
		</Wrapper>
	);
}
