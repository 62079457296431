export interface Palette {
	50: string;
	100: string;
	200: string;
	300: string;
	400: string;
	500: string;
	600: string;
	700: string;
	800: string;
	900: string;
	1000: string;
}

export const black: string = 'hsla(0, 0%, 0%, 1)';
export const white: string = 'hsla(0, 100%, 100%, 1)';

export const primary: Palette = {
	50: 'hsla(12, 85.25%, 97.16%, 1)',
	100: 'hsla(12, 85.25%, 92.16%, 1)',
	200: 'hsla(12, 85.25%, 82.16%, 1)',
	300: 'hsla(12, 85.25%, 72.16%, 1)',
	400: 'hsla(12, 85.25%, 62.16%, 1)',
	500: 'hsla(12, 85.25%, 52.16%, 1)',
	600: 'hsla(12, 85.25%, 42.16%, 1)',
	700: 'hsla(12, 85.25%, 32.16%, 1)',
	800: 'hsla(12, 85.25%, 22.16%, 1)',
	900: 'hsla(12, 85.25%, 12.16%, 1)',
	1000: 'hsla(12, 85.25%, 7.16%, 1)',
};

export const secondary: Palette = {
	50: 'hsla(16, 46.09%, 99.90%, 1)',
	100: 'hsla(16, 46.09%, 94.90%, 1)',
	200: 'hsla(16, 46.09%, 84.90%, 1)',
	300: 'hsla(16, 46.09%, 74.90%, 1)',
	400: 'hsla(16, 46.09%, 64.90%, 1)',
	500: 'hsla(16, 46.09%, 54.90%, 1)',
	600: 'hsla(16, 46.09%, 44.90%, 1)',
	700: 'hsla(16, 46.09%, 34.90%, 1)',
	800: 'hsla(16, 46.09%, 24.90%, 1)',
	900: 'hsla(16, 46.09%, 14.90%, 1)',
	1000: 'hsla(16, 46.09%, 9.90%, 1)',
};

export const tertiary: Palette = {
	50: 'hsla(16, 10.92%, 100.00%, 1)',
	100: 'hsla(16, 10.92%, 95.10%, 1)',
	200: 'hsla(16, 10.92%, 85.10%, 1)',
	300: 'hsla(16, 10.92%, 75.10%, 1)',
	400: 'hsla(16, 10.92%, 65.10%, 1)',
	500: 'hsla(16, 10.92%, 55.10%, 1)',
	600: 'hsla(16, 10.92%, 45.10%, 1)',
	700: 'hsla(16, 10.92%, 35.10%, 1)',
	800: 'hsla(16, 10.92%, 25.10%, 1)',
	900: 'hsla(16, 10.92%, 15.10%, 1)',
	1000: 'hsla(16, 10.92%, 10.10%, 1)',
};

export const accent: Palette = {
	50: 'hsla(161, 73.06%, 96.96%, 1)',
	100: 'hsla(161, 73.06%, 91.96%, 1)',
	200: 'hsla(161, 73.06%, 81.96%, 1)',
	300: 'hsla(161, 73.06%, 71.96%, 1)',
	400: 'hsla(161, 73.06%, 61.96%, 1)',
	500: 'hsla(161, 73.06%, 51.96%, 1)',
	600: 'hsla(161, 73.06%, 41.96%, 1)',
	700: 'hsla(161, 73.06%, 31.96%, 1)',
	800: 'hsla(161, 73.06%, 21.96%, 1)',
	900: 'hsla(161, 73.06%, 11.96%, 1)',
	1000: 'hsla(161, 73.06%, 6.96%, 1)',
};
